import React from "react";
import { ButtonContent } from "../components/global/boton/ButtonContent";

function ErrorPages() {

    return (
       
            <div className="md:max-w-full w-full">
                
                <div className="py-[100px] text-center">
                    <h3 className="capitalize ">page not found</h3>
                    <ButtonContent 
                        btnName={'return to home'}
                        btnLink='/'
                    />
                </div>
            </div>
    );
}

export default ErrorPages;
